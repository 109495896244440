;
(function($){

// Docu Ready
$(document).ready(function() {
    //$('html').animate({scrollTop:0}, 1);
    //submenu
    $('.navbar-wrapper .submenu').find('li.active').parents('li.has-children').addClass('active');

    //init SHORTCUTS
    if ($("#access-shortcuts-wrapper>ul").length > 0){
        $("#access-shortcuts-wrapper>ul").initShortcuts();
    }

    //menu shortcut
    $('.nav-shortcut').find('.nav-shortcut-item i').not('.fa-camera, .fa-search').on('click', function() {
        var $shortcutPanel = $('#access-shortcuts-wrapper').find('.nav-shortcut-panel');
        var $panelContent = $('#access-shortcuts-wrapper').find('.content-panel');
        
        if (!$(this).parent('li').hasClass('active')) {
            $(this).parent('li').addClass('active').siblings().removeClass('active');

            $shortcutPanel.addClass('opened');
            $('body').addClass('no-scroll');

            var stageWidth = $('.stage-wrapper-outer').outerWidth();
            var shortcutPanelWidth = $shortcutPanel.outerWidth();
            var shortcutContentWidth = $panelContent.outerWidth();

            if (shortcutPanelWidth < stageWidth) {
                $shortcutPanel.width(stageWidth);
                $panelContent.width(stageWidth);
            }

        } else {
            $(this).parent('li').removeClass('active');
            $shortcutPanel.removeClass('opened');
            $('body').removeClass('no-scroll');
        }

        $('#access-shortcuts-wrapper').find('.btn-close').on('click', function(){
            $('#access-shortcuts-wrapper').find('.nav-shortcut-panel').removeClass('opened');
            $('#access-shortcuts-wrapper').find('.nav-shortcut-item').removeClass('active');
            $('body').removeClass('no-scroll');
        });
    });

    //footer shortcut
    $('#footer').find('i.fa-calendar').parent('a').on('click', function() { $($('.fa.fa-calendar').get(0)).trigger('click'); });
    //$('#footer').find('i.fa-camera').parent('a').on('click', function() { $($('.fa.fa-camera').get(0)).trigger('click'); });

    //display of search filter
    $('.searchbar-wrapper').find('p.toggle').on('click', function(){
        $(this).parents('form').find('.form-wrapper').toggleClass('cloud-hidden');
        if ($(this).parents('form').find('.form-wrapper').hasClass('cloud-hidden')) {
            $(this).find('i').removeClass('fa-minus-square-o').addClass('fa-plus-square-o');
        } else {
            $(this).find('i').removeClass('fa-plus-square-o').addClass('fa-minus-square-o');
        }
    });

    //popin bookmark
    $('.nav-shortcut').find('a.bookmark').on('click', function(){
        swal.fire({
          title: 'Cette page a été ajoutée à vos favoris',
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        });
    });

    //editor front end
    $('.nav-shortcut').find('.contribution-text-editor').trumbowyg({
        btns: [
            ['undo', 'redo'],
            ['formatting'],
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['link'],
            ['insertImage'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['orderedList'],
            ['horizontalRule'],
            ['removeformat']
        ],
        removeformatPasted: true
    });

    //fix height for offer || e-commerce
    if ($('.offer').hasClass('fix-height')) {
        $('.offer').parent('.colcontentset').addClass('fix-height');
        $('.offer').parents('.row').addClass('flex');
    }

    //tag select
    $(".dropdown dt a").on('click', function() {
       $(".dropdown dd ul").slideToggle('fast');
    });

    $(".dropdown dd ul li a").on('click', function() {
       $(".dropdown dd ul").hide();
    });

    function getSelectedValue(id) {
       return $("#" + id).find("dt a span.value").html();
    }

    $('.mutliSelect input[type="checkbox"]').on('click', function() {
        var title = $(this).closest('.mutliSelect').find('input[type="checkbox"]').val(),
        title = $(this).val() + ",";

        if ($(this).is(':checked')) {
            var html = '<span title="' + title + '">' + title + '</span>';
            $('.multiSel').append(html);
            $(".placeholder-text").hide();
        } else {
            $('span[title="' + title + '"]').remove();
            var ret = $(".placeholder-text");
            $('.dropdown dt a').append(ret);
        }

        if ($('.multiSel').html().length === 0) {
            $(".placeholder-text").show();
        }
    });

    //Placeholder Fix (no Modernizr)
    $('.lt-ie10 [placeholder]').focus(function() {
        var input = $(this);
        if (input.val() == input.attr('placeholder')) {
            input.val('');
            input.removeClass('placeholder');
        }
    }).blur(function() {
        var input = $(this);
        if (input.val() == '' || input.val() == input.attr('placeholder')) {
            input.addClass('placeholder');
            input.val(input.attr('placeholder'));
        }
    }).blur();
    $('.lt-ie10 [placeholder]').parents('form').submit(function() {
        $(this).find('.lt-ie10 [placeholder]').each(function() {
            var input = $(this);
            if (input.val() == input.attr('placeholder')) {
                input.val('');
            }
        })
    });

    //Printable version
    $('.btn-printer').on('click', function(e) {
        e.preventDefault();
        window.print();
    });

    //Video RWD
    if ($(".content-video iframe").length > 0) {
        $(".content-video iframe").resizeEmbed();
    }
    //

    //Close menu mobile by clicking on menu links
    $(".navbar-nav li a").click(function(event) {
        $(".navbar-collapse").collapse('hide');
    });

    var menuHeight = window.innerHeight - 60;
    $("#navbar-primary").on('show.bs.collapse', function () {
        $("#header").addClass("menu-active");
        $('body').css('overflow', 'hidden');
        $(".container-header .navbar-wrapper").css({'max-height': menuHeight + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden'});
    });

    $("#navbar-primary").on('hide.bs.collapse', function () {
        $("#header").removeClass("menu-active");
        $('body').css('overflow', '');
        $(".container-header .navbar-wrapper").css('max-height', 'auto');
    });

    $('.show-submenu').on('click', function () {
        $(this).closest('.has-children').find('.submenu').toggleClass('show');
    });

    //Affix
    // $('.navbar-fixed-top').affix({
    //     offset: {
    //         top:50
    //     }
    // });

    // scrollspy
    $('body').scrollspy({
        target: '#navbar-primary',
        offset: 60
    });

    //Filter
    $(".filter-label").click(function() {
        $(".filter-label").removeClass("active");
        var filter = $(this).addClass('active').data('filter');
        if (filter) {
            gallery.filter(filter);
        } else {
            gallery.unFilter();
        }
    });

    $('body').on('click', '[data-link]', function () {
        var link = jQuery(this).data('link');

        window.location = link;
    });

    if (window.location.hash) {
        $(window.location.hash).trigger('click');
    }

    //
    $('#message-list').find('h3').on('click', function() {
        $(this).next('.article-body').slideToggle();
    });

    $('.share-rs a.btn-print-photo').off('click').on('click', function (e) {
        var lightbox = $(e.target).parents('div.result-lightbox');

        PrintImage(
            lightbox.find('img').attr('src'),
            lightbox.find('h1').html(),
            lightbox.find('p').html()
        );

        return false;
    });

    $('.share-rs a.btn-download').off('click').on('click', function (e) {
        var lightbox = $(e.target).parents('div.result-lightbox');

        $(e.target).attr('href', lightbox.find('img').attr('src'));

        return true;
    });

    $(window).on('scroll', function() {
        $('.fixed-top')[$(window).scrollTop() > 0 ? 'addClass' : 'removeClass']('sticky');
    });
    
    var owl = $('.lazy-carousel');
    owl.on('translate.owl.carousel', function (e) {
       var src = owl.find('.owl-item.active').next().find('img').attr('src');
       owl.find('.owl-item.active').next().find('img').attr('src','').attr('src',src);
    });
    
//END ready
});

//
})(jQuery);

var PrintImage = function (source, title, desc) {
    var pwa = window.open("about:blank", "_new");
    pwa.document.open();
    pwa.document.write("<html><head><script>function step1(){\n" +
            "setTimeout('step2()', 10);}\n" +
            "function step2(){window.print();window.close()}\n" +
            "</scri" + "pt></head><body onload='step1()'>\n" +
            "<img src='" + source + "' /><br/><p><strong>" + title +
            "</strong><br/><br/>" + desc + "</p></body></html>");
    pwa.document.close();
}